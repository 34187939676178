
import { Definition } from "../types/index";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    displayObject: Object as () => Record<string, Definition>,
    listType: String,
  },
});
